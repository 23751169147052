// import function to register Swiper custom elements
import Swiper from "swiper";
import { Navigation, Autoplay } from "swiper/modules";
// register Swiper custom elements
// register();
document.addEventListener("DOMContentLoaded", () => {
  const nextEl = document.querySelector(".us-button-next");
  const prevEl = document.querySelector(".us-button-prev");
  const slider = new Swiper(".us-swiper", {
    modules: [Autoplay, Navigation],
    // slidesPerView: 1,
    // spaceBetween: 30,
    // initialSlide: 1,
    // centeredSlides: true,
    loop: true,
    navigation: {
      nextEl,
      prevEl,
    },
    autoplay: {
      delay: 4000,
    },
    speed: 600,
    breakpoints: {
      640: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 50,
      },
    },
  });
});
